import { StaticQuery, graphql } from "gatsby"
import React from "react"
import "./infoblocks.scss"
import Img from "gatsby-image"
import LocalizedLink from "../utils/LocalizedLink"

const InfoBlocksOld = props => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allInfoblocksJson {
          edges {
            node {
              title
              text
              linkText
              language
            }
          }
        }
      }
    `}
    
    render={data => {
      const content = data.allInfoblocksJson.edges.filter(
        d => d.node.language === props.locale
      )
      const banner = props.banner

      return (
        <div>
          <div className="banner-wrapper banner-wrapper-infoblock d-flex justify-content-center d-flex align-items-center">
            <Img fluid={banner.childImageSharp.fluid} />
            <div className="container justify-content-center">
              <div className="row  align-items-stretch">
                <div className="col d-flex justify-content-center">
                  <div className="block">
                    <div className="text-default bold block--title">
                      {content[0].node.title}
                    </div>
                    <div className="text-default block--desc">
                      {content[0].node.text}
                    </div>
                    <LocalizedLink
                      to="/sobre"
                      locale={props.locale}
                      className="text-default block--link">
                      {content[0].node.linkText}
                    </LocalizedLink>
                  </div>
                </div>
                <div className="col d-flex justify-content-center">
                  <div className="block">
                    <div className="text-default bold block--title">
                      {content[1].node.title}
                    </div>
                    <div className="text-default block--desc">
                      {content[1].node.text}
                    </div>
                    <LocalizedLink
                      to="/pessoas"
                      locale={props.locale}
                      className="text-default block--link">
                      {content[1].node.linkText}
                    </LocalizedLink>
                  </div>
                </div>
                <div className="col d-flex justify-content-center">
                  <div className="block">
                    <div className="text-default bold block--title">
                      {content[2].node.title}
                    </div>
                    <div className="text-default block--desc">
                      {content[2].node.text}
                    </div>
                    <LocalizedLink
                      to="/projetos"
                      locale={props.locale}
                      className="text-default block--link">
                      {content[2].node.linkText}
                    </LocalizedLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }}
  />
)

const InfoBlocks = props => {
  const banner = props.banner;
  const content = props.blocks;

  return (
    <div>
      <div className="banner-wrapper banner-wrapper-infoblock d-flex justify-content-center d-flex align-items-center">
        <Img fluid={banner.childImageSharp.fluid} />
        <div className="container justify-content-center">
          <div className="row  align-items-stretch">
            <div className="col d-flex justify-content-center">
              <div className="block">
                <div className="text-default bold block--title">
                  {content[0].infoBlockTitle}
                </div>
                <div className="text-default block--desc">
                  {content[0].text}
                </div>
                <LocalizedLink
                  to="/sobre"
                  locale={props.locale}
                  className="text-default block--link">
                  {content[0].linkText}
                </LocalizedLink>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="block">
                <div className="text-default bold block--title">
                  {content[1].infoBlockTitle}
                </div>
                <div className="text-default block--desc">
                  {content[1].text}
                </div>
                <LocalizedLink
                  to="/pessoas"
                  locale={props.locale}
                  className="text-default block--link">
                  {content[1].linkText}
                </LocalizedLink>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="block">
                <div className="text-default bold block--title">
                  {content[2].infoBlockTitle}
                </div>
                <div className="text-default block--desc">
                  {content[2].text}
                </div>
                <LocalizedLink
                  to="/projetos"
                  locale={props.locale}
                  className="text-default block--link">
                  {content[2].linkText}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBlocks

import React from "react"
import Layout from "../components/layout"
import Banner from "../components/Banner/banner2"
import InfoBlock from "../components/InfoBlocks/infoblocks"
import { graphql } from "gatsby"

export default ({ pageContext: { locale, currentPath }, data }) => {
  //const content = data.file.childSobreJson
  const content = data.aboutus

  return (
    <Layout locale={locale} currentPath={currentPath}>
      <Banner
        banner={content.Banner.background.localFile}
        text={content.Banner.bannerTitle}
        layer={true}
        opacityValue={0.1}
        //logo={obcrei}
      />
      <div className="container" style={{ marginBottom: 100 }}>
        <div className="row justify-content-md-center">
          <div className="col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 100, marginBottom: 45 }}>
              {content.Industrias.blockTitle}
            </h2>
          </div>
          <div
            className="col-sm-10 col-xs-12 text-default color-primary"
            dangerouslySetInnerHTML={{
              __html: content.Industrias.description.data.description,
            }}></div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 70, marginBottom: 45 }}>
              {content.CreiaLab.blockTitle}
            </h2>
          </div>
          <div
            className="col-sm-10 col-xs-12 text-default color-primary"
            dangerouslySetInnerHTML={{
              __html: content.CreiaLab.description.data.description,
            }}>
            {/* <p>{content.secondParagraphCreilab}</p>
            <p>{content.thirdParagraphCreilab}</p> */}
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 70, marginBottom: 45 }}>
              {content.Valores.blockTitle}
            </h2>
          </div>
          <div className="col-sm-10 col-xs-12 text-default color-primary">
            <div
              // style={{ lineHeight: "32px", paddingLeft: 30 }}
              dangerouslySetInnerHTML={{
                __html: content.Valores.description.data.description,
              }}
            />
          </div>
        </div>

        <div className="row justify-content-md-center">
          <div className="col col-sm-10 col-xs-12 text-left ">
            <h2 style={{ marginTop: 70, marginBottom: 45 }}>
              {content.Objetivos.blockTitle}
            </h2>
          </div>
          <div
            className="col-sm-10 col-xs-12 text-default color-primary"
            dangerouslySetInnerHTML={{
              __html: content.Objetivos.description.data.description,
            }}>
            {/* <p>{content.secondParagraphCreilab}</p>
            <p>{content.thirdParagraphCreilab}</p> */}
          </div>
          {/* <div className="col-sm-10 col-xs-12 text-default color-primary">
            <ul
              style={{ lineHeight: "32px", paddingLeft: 60 }}
              dangerouslySetInnerHTML={{
                __html: content.listObjectives,
              }}
            />
          </div>

          <div
            className="col-sm-10 col-xs-12 text-default color-primary mt-5"
            dangerouslySetInnerHTML={{
              __html: content.paragraphObjectives,
            }}
          />

          <div className="col-sm-10 col-xs-12 text-default color-primary">
            <ul
              style={{
                lineHeight: "32px",
                paddingLeft: 60,
                marginTop: 45,
                marginbottom: 45,
              }}
              dangerouslySetInnerHTML={{
                __html: content.secondListObjectives,
              }}
            />
          </div>

          <div className="col-sm-10 col-xs-12 text-default color-primary mt-5">
            {content.secondParagraphObjectives}
          </div> */}
        </div>
      </div>
      <InfoBlock
        banner={content.bannerInfoblocks.localFile}
        blocks={content.Infoblocks}
        locale={locale}
      />
    </Layout>
  )
}

export const query = graphql`
  query AboutUs($locale: String!) {
    aboutus: strapiAboutUs(locale: { eq: $locale }) {
      Banner {
        bannerTitle
        background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, maxHeight: 294) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      bannerInfoblocks {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 495) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      CreiaLab {
        blockTitle
        description {
          data {
            description
          }
        }
      }
      Industrias {
        blockTitle
        description {
          data {
            description
          }
        }
      }
      Infoblocks {
        infoBlockTitle
        text
        linkText
      }
      Objetivos {
        blockTitle
        description {
          data {
            description
          }
        }
      }
      Valores {
        blockTitle
        description {
          data {
            description
          }
        }
      }
    }
  }
`

// export const sobreContent = graphql`
//   query($locale: String) {
//     file(
//       name: { eq: $locale }
//       relativeDirectory: { eq: "textContent/sobre" }
//     ) {
//       childSobreJson {
//         bannerText
//         titleLab
//         titleIndustries
//         textIndustries
//         secondParagraphCreilab
//         thirdParagraphCreilab
//         titleValues
//         listValues
//         titleObjectives
//         listObjectives
//         paragraphObjectives
//         secondListObjectives
//         secondParagraphObjectives
//         bannerImage {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 294) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//         bannerInfoblocks {
//           childImageSharp {
//             fluid(maxWidth: 1200, maxHeight: 495) {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//     }
//   }
// `
